


































































import {
  AppAnnotation2Answers,
  AppAnnotation2Options
} from "@/interfaces/candidate/canidate_assessments";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "AppAnnotationPart2",
  data() {
    return {
      options: [] as AppAnnotation2Answers[],
      responses: [
        { text: "Response 1", index: 0 },
        { text: "Response 2", index: 1 },
        { text: "Response 3", index: 2 },
        { text: "Response 4", index: 3 }
      ]
    };
  },
  props: {
    question: {
      type: Object as PropType<AppAssessments.AssessmentQuestionnaires>,
      required: true
    }
  },
  mounted() {
    if (
      this.question.question_options &&
      this.question.question_options.responses
    ) {
      const responses = this.question.question_options.responses;
      for (let res of responses) {
        if (!res.content) continue;
        if (!res.options || !res.options.length) continue;
        const content = res.content;
        const options: AppAnnotation2Options[] = [];
        for (let option of res.options) {
          options.push({
            option1: option.option1,
            option2: option.option2,
            op1_selected: false,
            op2_selected: false
          });
        }
        this.options.push({ content, options });
      }
    }
  },
  methods: {
    process_ans(question_index: number, op_index: number, op: string) {
      if (op === "1") {
        this.options[question_index].options[op_index].op1_selected = true;
        this.options[question_index].options[op_index].op2_selected = false;
      } else {
        this.options[question_index].options[op_index].op1_selected = false;
        this.options[question_index].options[op_index].op2_selected = true;
      }
      this.$emit("annotation-2", this.options);
    }
  }
});
