








































import Vue from "vue";
import { generate_random_key } from "@/utils/global";

export default Vue.extend({
  name: "InterviewLoading",
  methods: { generate_random_key }
});
