
































































import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { generate_random_key } from "@/utils/global";
import Vue, { PropType } from "vue";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { mapGetters } from "vuex";
import { SiteDirections } from "@/store/modules/common/interfaces";
export default Vue.extend({
  name: "AppAnnotationPart1",
  data() {
    return {
      mouse_btn_icon: require("@/assets/icons/linear/mouse-square.svg"),
      dragging_index: -1,
      chips_key: generate_random_key(),
      responses: [
        { text: "Response 1", index: 0 },
        { text: "Response 2", index: 1 },
        { text: "Response 3", index: 2 },
        { text: "Response 4", index: 3 }
      ]
    };
  },
  props: {
    question: {
      type: Object as PropType<AppAssessments.AssessmentQuestionnaires>,
      required: true
    }
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirection() {
      return SiteDirections;
    }
  },
  methods: {
    /**
     * @description: This method is used to drag the chip and set the dragging index
     * @param {number} index - index of the chip which is dragged by the user
     */
    on_drag_start(index: number) {
      this.dragging_index = index;
    },
    /**
     *
     */
    on_drag_over(event: any) {
      event.preventDefault();
    },
    /**
     * @description: This method is used to swap the chips when the chip is dropped on the other chip
     *  and set the dragging index to -1 after the drop is completed successfully
     * @param {number} target_index - index of the chip on which the dragged chip is dropped
     */
    on_drop(target_index: number) {
      if (this.dragging_index !== -1) {
        const draggedChip = this.responses[this.dragging_index]; // dragged chip
        const target_chip = this.responses[target_index]; // chip on which the dragged chip is dropped
        this.responses[this.dragging_index] = target_chip; // set the dragged chip to the target chip
        this.responses[target_index] = draggedChip; // set the target chip to the dragged chip
        this.dragging_index = -1; // set the dragging index to -1
        this.chips_key = generate_random_key(); // generate the random key to re-render the chips
      }
    },
    /**
     * @description: This method is used to set the dragging index to -1 when the drag is ended
     * and emit the event to the parent component
     */
    on_drag_end() {
      this.dragging_index = -1;
      this.$emit("on_drag_end", this.responses);
    },
    on_copy(event: any) {
      event.preventDefault();
    }
  }
});
