





































import { AppAnnotation3Answers } from "@/interfaces/candidate/canidate_assessments";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "AppAnnotationPart3",
  data() {
    return {
      options: [] as AppAnnotation3Answers[]
    };
  },
  props: {
    question: {
      type: Object as PropType<AppAssessments.AssessmentQuestionnaires>,
      required: true
    }
  },
  mounted() {
    if (
      this.question.question_options &&
      this.question.question_options.responses
    ) {
      for (let res of this.question.question_options.responses) {
        if (!res.content || !res.arabic) continue;
        this.options.push({
          answer: "",
          arabic: res.arabic,
          content: res.content
        });
      }
    }
  },
  methods: {
    submit_ans() {
      this.$emit("annotation-3", this.options);
    },
    event_cancellation(event: any) {
      event.preventDefault();
    }
  }
});
