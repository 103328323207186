




















































































import Vue, { PropType } from "vue";
import { AppAnnotation5Answers } from "@/interfaces/candidate/canidate_assessments";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { mapGetters } from "vuex";
import { SiteDirections } from "@/store/modules/common/interfaces";
export default Vue.extend({
  name: "AppAnnotationPart5",
  data() {
    return {
      options: [] as AppAnnotation5Answers[],
      index: 0
    };
  },
  props: {
    question: {
      type: Object as PropType<AppAssessments.AssessmentQuestionnaires>,
      required: true
    }
  },
  created() {
    if (
      this.question.question_options &&
      this.question.question_options.question_material
    ) {
      for (let i = 0; i < 3; i++) {
        this.options.push({
          answer: "",
          question: ""
        });
      }
    }
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirection() {
      return SiteDirections;
    }
  },
  methods: {
    submit_ans() {
      this.$emit("annotation-5", this.options);
    },
    event_cancellation(event: any) {
      event.preventDefault();
    }
  }
});
